import React, { useContext, useEffect, useState } from "react";
import './header.css';
import logo from '../assets/images/logo.svg';
import { Link } from "react-router-dom";
import { LoginContext } from "../Context/LoginContext";

const Header = () => {
    const playerID = localStorage.getItem("widget-playerId")
  const tokenID = localStorage.getItem("widget-token")

  const {logButton,setLogButton}= useContext(LoginContext)


  useEffect(() => {
    if(playerID && tokenID)
    {
        setLogButton(true)
    }
  
    
  }, [])
  


    const handleLogout =() =>{
        localStorage.removeItem('widget-playerId')
        localStorage.removeItem('widget-token')
        localStorage.removeItem('placedBetData')
        localStorage.removeItem('totalSystemStake')
        
        setLogButton(false)

        window.dispatchEvent(
            new CustomEvent('playerLoggedOut', {
              
            })
          )

    }

    return (
        <div className="header">
             <div className="logo">
                <img src={logo} alt="Logo"/>
            </div>
            <aside>
        {
            logButton ? 
            <button className="button-widget" onClick={handleLogout}>Logout</button> 
            : <Link className="button-widget" to={'/login'}>Login</Link>
        } 
                
                <Link to={'/register'} className="button-widget secondary">Register</Link>
            </aside>
        </div>
    )
}
export default Header;