import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

const Widget = ({ setBackTo }) => {
  const navigate = useNavigate();

  // useEffect(() => {
  //   document
  //     .getElementById("scriptLoad")
  //     .addEventListener("triggerSignUpFlow", function (event) {
  //       console.log("triggerSignUpFlow event from widget");
  //       setBackTo(event.detail.url);
  //       navigate(event.detail.redirectUrl);

  //       // alert(event.detail.name);
  //       // window.location.href="/login"
  //     });

  //   document
  //     .getElementById("scriptLoad")
  //     .addEventListener("triggerLoginFlow", function (event) {
  //       console.log("triggerLoginFlow event from widget");
  //       setBackTo(event.detail.url);
  //       navigate(event.detail.redirectUrl);

  //       // alert(event.detail.name);
  //       // window.location.href="/login"
  //     });

  //   window.addEventListener("triggerBalanceUpdate", function (event) {
  //     console.log(
  //       "triggerBalanceUpdate event from widget, Bet placed with stakes :-",
  //       event.detail.bets
  //     );
  //   });

  //   window.addEventListener("triggerDepositFlow", function (event) {
  //     console.log(event.detail.params, "triggering event ");
  //     navigate("/deposit", { state: { url: event.detail.redirectURL } });
  //   });
  // }, []);
  return (
    <div>
      <div className="content-widget">
        <div id="b2b-widget"></div>
        {/* <leetent-social-bettor></leetent-social-bettor>; */}
        {/* <leetent-navigation-generic product="eSports"></leetent-navigation-generic> */}
      </div>
    </div>
  );
};

export default Widget;
