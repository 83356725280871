import React, { useEffect } from "react";

const Sports = () => {
  return (
    <div>
      <div id="b2b-sports"></div>
    </div>
  );
};

export default Sports;
