import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./SubHeader.css";

const SubHeader = ({ activeTab, setActiveTab }) => {
  const location = useLocation();
  //   console.log(activeTab, location.pathname)
  return (
    <div className={`sub-header ${activeTab === "tab2" ? "is-esports" : ""}`}>
      <Link
        to={"/sports"}
        className={activeTab === "tab1" ? "is-sports" : ""}
        onClick={() => setActiveTab("tab1")}
      >
        SPORTS
      </Link>
      <Link
        to={"/esports"}
        className={activeTab === "tab2" ? "is-esports" : ""}
        onClick={() => setActiveTab("tab2")}
      >
        ESPORTS
      </Link>
    </div>
  );
};
export default SubHeader;
