import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "../Context/LoginContext";

const Register = ({ backTo }) => {
    const navigate = useNavigate();
    const { logButton, setLogButton } = useContext(LoginContext);
    const [input, setInput] = useState(null)

    const handleRegister = () => {
        localStorage.setItem("widget-playerId", input || "1");
        localStorage.setItem("widget-token", "12345678");

        setLogButton(true);
        window.dispatchEvent(new CustomEvent("playerLoggedIn", {}));
        console.log(backTo, "backto.....to redirect");
        if (backTo == "") {
            backTo = "/";
        }

        navigate(backTo);
    };

    return (
        <div className="form-wrap">
            <input type={"text"} placeholder="Enter PlayerID" value={input} onChange={(e)=> setInput(e.target.value) } />
            <button className="button-widget secondary" onClick={handleRegister}>
                Register
            </button>
        </div>
    );
};

export default Register;
