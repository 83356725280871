import React, { useContext, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { LoginContext } from '../Context/LoginContext';

const Login = ({backTo, setActiveTab}) => {
  const navigate = useNavigate()
  const [input, setInput] = useState(null)
  // console.log("redirect url",backTo)
  const {logButton,setLogButton}= useContext(LoginContext)

  const handleRedirect = ()=>{

    

    localStorage.setItem("widget-playerId", input || "1")
    localStorage.setItem("widget-token","12345678")

    
    window.dispatchEvent(
      new CustomEvent('playerLoggedIn', {
        
      })
    )
    // console.log(backTo,"backto.....to redirect")
    if(backTo == "")
    {
      backTo="/"
      setActiveTab('tab1')
    }
    setLogButton(true)

    navigate(backTo)
  }

  // console.log(backTo, "checking where")

  return (
    <div className='form-wrap'>
        <input type={'text'} value={input} onChange={(e)=> setInput(e.target.value) } placeholder="Enter PlayerID" />
        <button className='button-widget secondary' onClick={handleRedirect}>Login</button>
    </div>
  )
}

export default Login